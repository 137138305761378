import React, { useState } from "react"
import { CurrencyContext } from "./"

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("gbp")

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  )
}

export default CurrencyProvider
